import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import parse from "html-react-parser";

const LeaseCardStyles = styled.div`
  grid-column-end: span 4;
  text-align: center;
  margin-bottom: 3em;
  &:nth-child(n + 4) {
    margin-bottom: 0;
    @media ${(props) => props.theme.breakpoints.m} {
      margin-bottom: 2em;
    }
  }

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column-end: span 6;
    margin-bottom: 2em;
  }

  .wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto;
    .image {
      width: 100%;
      height: 16em;
      overflow: hidden;
      margin-bottom: 1em;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }
    .step-number {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 6;
      width: 3em;
      height: 3em;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-accent);
      color: var(--color-white);
      /* border-radius: 50%; */
    }
  }
`;

const LeaseCard = ({ content, number, image, imageAlt }) => {
  return (
    <LeaseCardStyles>
      <div className="wrapper">
        <div className="image">
          <GatsbyImage image={image} alt={imageAlt} />
        </div>
        <div className="step-number">{number}</div>
      </div>

      <p>{parse(content)}</p>
    </LeaseCardStyles>
  );
};

export default LeaseCard;
