import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import TopTitleBar from "../components/TopTitleBar";
import { useStaticQuery, graphql } from "gatsby";
import MaxWidthSection from "../components/Layout/MaxWidthSection";
import LeaseCard from "../components/LeaseCard";

const LeaseStyles = styled.main`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }
`;

const Lease = () => {
  const data = useStaticQuery(graphql`
    query LeaseQuery {
      allSanityLeasePage {
        nodes {
          title
          content
          steps {
            description
            _key
            imageAlt
            cardNumber
            stepImage {
              asset {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 400
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const title = data.allSanityLeasePage.nodes[0].title;
  const steps = data.allSanityLeasePage.nodes[0].steps;

  return (
    <Layout>
      <Seo title="Lease" />
      <TopTitleBar title={title} />
      <LeaseStyles>
        <MaxWidthSection>
          {steps.map((step) => (
            <LeaseCard
              key={step._key}
              content={step.description}
              imageAlt={step.imageAlt}
              number={step.cardNumber}
              image={
                step.stepImage.asset.localFile.childImageSharp.gatsbyImageData
              }
            />
          ))}
        </MaxWidthSection>
      </LeaseStyles>
    </Layout>
  );
};

export default Lease;
